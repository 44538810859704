import { useContext, useEffect, useState } from "react";

import { usePostHog } from "posthog-js/react";

import { toast } from "sonner";

import { RiClipboardLine } from "@remixicon/react";

import { ApiContext } from "../../../providers/ApiProvider";
import { ActionPlanContext } from "../../../providers/ActionPlanProvider";

import LoadingSpinner from "../../UI/LoadingSpinner";
import TabsPills from "../../UI/TabsPills";

import ActionItem from "./ActionItem";

const states = {
  thinking: "Thinking About",
  working: "Working On",
  finished: "Finished",
};

const tabs = {
  all: "All",
  thinking: "Thinking About",
  working: "Working On",
  finished: "Finished",
};

function generateRecommendationTable(recommendations) {
  if (!recommendations || recommendations.length === 0) {
    return "No recommendations available";
  }

  const items = {};
  Object.values(tabs).forEach((tab) => (items[tab] = []));

  recommendations.forEach((rec) => {
    const itemContent = rec?.content || rec?.recommendation?.dynamic_content || rec?.recommendation?.content;
    const state = states[rec.state];

    items[state].push(itemContent);
  });

  let result = "";
  Object.keys(items).forEach((key) => {
    if (items[key].length > 0) {
      result += `Patient ${key}:\n`;
      items[key].forEach((item) => {
        result += `* ${item}\n`;
      });
      result += "\n";
    }
  });

  result = "Prescribed Lifestyle Medicine Recommendations:\n\n" + result;

  return result.trim();
}

const ActionPlan = (props) => {
  const { patientId } = props;

  const posthog = usePostHog();

  const api = useContext(ApiContext);

  const { actionItems, add, remove, update, bulkAdd } = useContext(ActionPlanContext);

  const [curTab, setCurTab] = useState(tabs.all);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingItem, setIsSavingItem] = useState({});
  const [actionItemsFiltered, setActionItemsFiltered] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/patients/${patientId}/recommendations`)
      .then((resp) => {
        bulkAdd(resp.data.patients_recommendations);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.client, bulkAdd, patientId]);

  useEffect(() => {
    const filter = curTab === tabs.all ? actionItems : actionItems.filter((r) => tabs[r.state] === curTab);
    setActionItemsFiltered(filter);
  }, [actionItems, curTab]);

  const handleAdd = (data) => {
    setIsAddNew(false);
    setIsLoading(true);

    api.client
      .post(`/patients/${patientId}/recommendations`, { content: data.content })
      .then((resp) => {
        if (resp.status === 204) {
          posthog?.capture("assign_recommendation", {
            patient_id: patientId,
            content: data.content,
            already_added: true,
          });
          toast.warning("Action already added to patient.");
        } else {
          posthog?.capture("assign_recommendation", {
            patient_id: patientId,
            content: data.content,
            already_added: false,
          });
          add(resp.data.patients_recommendation);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = (data) => {
    setIsSavingItem((prev) => ({ ...prev, [data.id]: true }));
    api.client
      .put(`/patients/${patientId}/recommendations/${data.id}`, {
        content: data.content,
        state: data.state,
        notify: data.notify,
      })
      .then((resp) => {
        posthog?.capture("update_recommendation", {
          patient_id: patientId,
          action_item_id: data.id,
          content: data.content,
          state: data.state,
        });
        update(resp.data.patients_recommendation);
        setIsSavingItem((prev) => ({ ...prev, [data.id]: false }));
      })
      .catch(() => {
        setIsSavingItem((prev) => ({ ...prev, [data.id]: false }));
      });
  };

  const handleDelete = (id) => {
    setIsSavingItem((prev) => ({ ...prev, [id]: true }));
    api.client
      .delete(`/patients/${patientId}/recommendations/${id}`)
      .then(() => {
        posthog?.capture("remove_recommendation", {
          patient_id: patientId,
          action_item_id: id,
        });
        remove(id);
        setIsSavingItem((prev) => ({ ...prev, [id]: false }));
      })
      .catch(() => {
        setIsSavingItem((prev) => ({ ...prev, [id]: false }));
      });
  };

  const handleCreateOrUpdate = (data) => {
    if (data.id && data.id !== -1) {
      handleUpdate(data);
    } else {
      handleAdd(data);
    }
  };

  const handleCopyPlan = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(generateRecommendationTable(actionItems));
    toast.success("Action Plan copied to clipboard.");
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner color="text-indigo-500" />
      ) : (
        <div className="relative w-full">
          <div className="flex items-center justify-between border-b border-slate-300 pb-2">
            <div className="flex w-full items-center justify-start">
              <TabsPills tabs={Object.values(tabs)} curTab={curTab} onChange={(tab) => setCurTab(tab)} />
            </div>
            <div className="flex justify-end basis-1/5">
              <button
                type="button"
                onClick={handleCopyPlan}
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <RiClipboardLine className="w-5 h-5 mr-2" />
                Copy Plan
              </button>
            </div>
          </div>
          <ul className="divide-y divide-slate-200 gap-y-2">
            {actionItemsFiltered.map((r) => (
              <ActionItem
                key={r?.id}
                item={r}
                onDelete={handleDelete}
                onSave={handleCreateOrUpdate}
                isLoading={isSavingItem[r?.id]}
              />
            ))}

            {isAddNew ? (
              <ActionItem
                item={{ id: -1 }}
                onDelete={handleDelete}
                onSave={handleCreateOrUpdate}
                isLoading={isSavingItem[-1]}
                onCancel={() => setIsAddNew(false)}
              />
            ) : (
              <li className="relative p-4 w-full flex flex-col items-center justify-between">
                <button
                  onClick={() => setIsAddNew(true)}
                  type="button"
                  className="inline-flex w-full px-4 py-2 text-sm items-center justify-center font-medium shadow-sm rounded-md text-indigo-800 ring-1 ring-indigo-400 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-indigo-100"
                >
                  Add Custom Recommendation
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default ActionPlan;
