import clsx from "clsx";

import { useContext, useEffect, useState } from "react";

import { usePostHog } from "posthog-js/react";

import { toast } from "sonner";

import { useInstantSearch } from "react-instantsearch";

import { ApiContext } from "../../providers/ApiProvider";
import { ActionPlanContext } from "../../providers/ActionPlanProvider";

import CustomSearchInput from "../Dashboard/Search/CustomSearchInput";
import SearchResultList from "../Dashboard/Search/SearchResultList";

import ActionItemResult from "./ActionPlan/ActionItemResult";
import ActionPlan from "./ActionPlan/ActionPlan";

const PatientDashboard = (props) => {
  const { patientId } = props;

  const posthog = usePostHog();

  const api = useContext(ApiContext);

  const { results: searchResults } = useInstantSearch();

  const { add } = useContext(ActionPlanContext);

  const [hasSearchResults, setHasSearchResults] = useState(false);

  useEffect(() => {
    setHasSearchResults(searchResults.hits.length > 0);
  }, [searchResults.hits]);

  const handleAdd = (data) => {
    api.client
      .post(`/patients/${patientId}/recommendations`, { recommendation_id: data.recommendation_id, state: data.state })
      .then((resp) => {
        if (resp.status === 204) {
          posthog?.capture("assign_recommendation", {
            patient_id: patientId,
            state: data.state,
            recommendation_id: data.recommendation_id,
            already_added: true,
          });
          toast.warning("Action already added to patient.");
        } else {
          posthog?.capture("assign_recommendation", {
            patient_id: patientId,
            state: data.state,
            recommendation_id: data.recommendation_id,
            already_added: false,
          });
          add(resp.data.patients_recommendation);
        }
      });
  };

  return (
    <>
      <div className="mt-5 mx-2 lg:mx-5 flex flex-col items-center">
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full">
            <div className="flex justify-center">
              <CustomSearchInput />
            </div>
          </div>
        </div>
        <div
          className={clsx(
            "flex flex-col-reverse lg:flex-row-reverse lg:gap-x-5 lg:gap-y-0 gap-y-5 justify-center my-5",
            hasSearchResults ? "w-full" : "w-full lg:w-3/4 "
          )}
        >
          <div
            className={clsx(
              "flex py-5 px-3 justify-center bg-white shadow ring-1 ring-slate-300 rounded-lg",
              hasSearchResults ? "w-full lg:w-2/5" : "w-full "
            )}
          >
            <ActionPlan patientId={patientId} />
          </div>
          {hasSearchResults && (
            <div className="flex py-5 w-full lg:w-3/5 justify-center bg-white shadow ring-1 ring-slate-300 rounded-lg">
              <SearchResultList resultComponent={ActionItemResult} onAdd={handleAdd} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientDashboard;
