/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";

import { Fragment, useContext, useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { usePostHog } from "posthog-js/react";

import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import { RiAddCircleLine } from "@remixicon/react";

import { AuthContext } from "../../providers/AuthProvider";
import { ApiContext } from "../../providers/ApiProvider";

import DetailsSlideOver from "../UI/DetailsSlideOver";
// import NotificationDropDown from "../UI/NotificationDropDown";
import PatientDetails from "../Patients/Details/PatientDetails";
import PatientSearchAutocomplete from "./PatientSearchAutocomplete";

const TopNav = () => {
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);

  const navigate = useNavigate();
  const location = useLocation();

  const posthog = usePostHog();

  const [isLoading, setIsLoading] = useState(false);
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [user, setUser] = useState();
  const [navigation, setNavigation] = useState([]);
  const [showPatients, setShowPatients] = useState(false);

  useEffect(() => {
    auth
      .currentUser()
      .then((u) => {
        setUser(u);
        setNavigation(() => {
          const res = [{ name: "Dashboard", href: "/dashboard" }];

          if (!!u.plan.features.find((f) => f.name === "Action Plan Management" && f.is_enabled)) {
            setShowPatients(true);
            res.push({ name: "Patients", href: "/patients" });
          }

          return [...res];
        });
      })
      .catch(() => {
        auth.logout().then(() => navigate("/"));
      });
  }, [auth, navigate]);

  useEffect(() => {
    if (user === undefined || user === null) return;

    // Identify sends an event, so you want may want to limit how often you call it
    posthog?.identify(user.id, {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
    });

    if (!!user.organization) {
      posthog?.group("clinic", user.organization.id);
    }
  }, [posthog, user]);

  const logout = () => {
    auth.logout().then(() => navigate("/"));
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  const navigateToClinic = () => {
    navigate("/clinic");
  };

  const profileDropDownNav = [
    { name: "Your Profile", onClick: navigateToProfile },
    { name: "My Clinic", onClick: navigateToClinic },
    { name: "Sign out", onClick: logout },
  ];

  const handleAdd = (data) => {
    setIsLoading(true);
    api.client
      .post(
        "/patients",
        { patient: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        posthog?.capture("add_patient", { patient_id: resp.data.patient.id });
        setShowAddPatient(false);
        setIsLoading(false);
        navigate(`/patients/${resp.data.patient.id}`);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const isCurrentLocation = (path) => {
    return location.pathname.split("/")[1] === path?.split("/")[1];
  };

  const handlePatientSelect = (patient) => {
    if (!patient) return;

    navigate(`/patients/${patient.id}`);
  };

  const navLink = (item) => {
    return (
      <a
        href={item.href}
        className={clsx(
          isCurrentLocation(item.href)
            ? "bg-slate-900 text-white"
            : "text-slate-300 hover:bg-slate-700 hover:text-white",
          "rounded-md px-3 py-2 text-base font-medium"
        )}
      >
        {item.name}
      </a>
    );
  };

  return (
    <>
      <Disclosure as="nav" className="bg-slate-800 sticky top-0 z-40">
        <>
          <div className="mx-auto max-w-7xl px-8 divide-y divide-slate-700">
            <div className="relative flex h-16 items-center justify-between py-2">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <a href="/dashboard">
                    <img className="h-auto w-11" src="/images/favicon.png" alt="Pro-Patient Tech" />
                  </a>
                </div>
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Fragment key={item.href}>{navLink(item)}</Fragment>
                    ))}
                  </div>
                </div>
              </div>
              {showPatients && (
                <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-xs">
                    <div className="relative">
                      <PatientSearchAutocomplete onSelect={handlePatientSelect} />
                    </div>
                  </div>
                </div>
              )}
              <div className="ml-4 block">
                <div className="flex items-center">
                  {showPatients && (
                    <>
                      <div className="hidden lg:flex lg:flex-shrink-0 mr-2">
                        <button
                          type="button"
                          onClick={() => setShowAddPatient(true)}
                          className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                          <RiAddCircleLine className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          Add New Patient
                        </button>
                      </div>

                      {/* <NotificationDropDown /> */}
                    </>
                  )}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <MenuButton className="relative flex rounded-full bg-slate-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-slate-800">
                        <span className="absolute -inset-1.5" />
                        {user?.photo ? (
                          <img className="h-8 w-8 rounded-full" src={user.photo} alt="" />
                        ) : (
                          <span className="inline-block overflow-hidden h-8 w-8 rounded-full bg-slate-100">
                            <svg className="h-full w-full text-slate-300" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                      </MenuButton>
                    </div>
                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      {profileDropDownNav.map((item) => (
                        <MenuItem key={item.name}>
                          <a
                            onClick={item.onClick}
                            className="cursor-pointer block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100"
                          >
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </>
      </Disclosure>

      {showAddPatient && (
        <DetailsSlideOver open={showAddPatient} onClose={() => setShowAddPatient(false)} title="Add a New Patient">
          <PatientDetails onClose={() => setShowAddPatient(false)} onChange={handleAdd} isLoading={isLoading} />
        </DetailsSlideOver>
      )}
    </>
  );
};

export default TopNav;
